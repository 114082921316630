import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Auth, Hub } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import WlLogo from '../WlLogo';
import { FaWindows } from 'react-icons/fa';


const theme = createTheme();

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © Williams Lea '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function Login() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        Hub.listen("auth", (data) => {
            switch (data.payload.event) {
                case "signIn":
                    setLoading(true)
                    localStorage.setItem("cognitoUser", true);
                    localStorage.setItem("idpLogin", true);
                    localStorage.setItem("isAuthenticated", true);
                    Auth.currentSession().then((response) => {
                        localStorage.setItem(
                            "userData",
                            JSON.stringify(response.idToken.payload)
                        );
                    });
                    navigate('/get-token')
                    setLoading(false)
                    break;
                case "signIn_failure":
                    // console.log("Sign In Failure");
                    setLoading(false)
                    break;
                default:
                    setLoading(false)
                    break;
            }
        });
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)
        const data = new FormData(event.currentTarget);
        try {
            localStorage.clear()
            await Auth.signIn(data.get('email'), data.get('password'));
            localStorage.setItem("isAuthenticated", "true");
            Auth.currentSession().then(response => {
                localStorage.setItem("userData", JSON.stringify(response.idToken.payload))
            })
            navigate('/get-token')
        } catch (e) {
            alert(e.message);
            setLoading(false)
        }
    };

    const FederatedLogin = () => {
        setLoading(true)
        Auth.federatedSignIn({ provider: "wl-logostore-dev-idp" })
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Backdrop open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <WlLogo />
                    <br />
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                    </Box>
                    <Button
                        onClick={FederatedLogin}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        <FaWindows /> &nbsp;&nbsp;
                        Sign In via Azure
                    </Button>
                    {/* Disabled Sign-Up Option */}
                    {/* or
                    <Button
                        onClick={() => { navigate('/signup') }}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign Up
                    </Button> */}
                </Box>
                <Copyright sx={{ mt: 5 }} />
            </Container>
        </ThemeProvider>
    );
}