const wlConfig = {
    Auth: {
        mandatorySignIn: true,
        region: "us-east-1",
        userPoolId: "us-east-1_IQZWW6Scv",
        identityPoolId:"us-east-1:793f94a5-9e2e-4b32-81d8-52c3f1e27fef",
        userPoolWebClientId: "2ogiaps4gpd8md07l96cmchosv"
    },
    Storage: {
        region: "us-east-1",
        bucket: "wl-automation-dev-document-repo",
        identityPoolId: "us-east-1:793f94a5-9e2e-4b32-81d8-52c3f1e27fef"
    },
    API: {
        endpoints: [
            {
                name: "wl-logostore-tester",
                endpoint: "https://az7sjaigel.execute-api.us-east-1.amazonaws.com/dev"
            }
        ]
    },
    oauth: {
        domain: "wl-logostore-dev.auth.us-east-1.amazoncognito.com",
        scope: ["email", "profile", "openid"],
        redirectSignIn:"https://web-app.wl-dev-logostore.com/login",
        redirectSignOut: "https://web-app.wl-dev-logostore.com/login",
        responseType: "code",
        clientId: "2ogiaps4gpd8md07l96cmchosv"

      }
}
export default wlConfig;